import React, { useEffect, useState } from "react";
import { useVendorRegisterMutation } from "../../store/api/AuthSlice";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import statesAndCities from "../../json/CityAndState";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
const VendorRegister = () => {
  const navigate = useNavigate();
  const [vendorRegister] = useVendorRegisterMutation();

  // Initial form values
  const initialValues = {
    role: "Vendor",
    password: "",
    firstName: "",
    lastName: "",
    contactPersonTitle: "",
    email: "",
    phone: "",
    city: "",
    state: "",
    zipcode: "",
    businessName: "",
    businessWebsiteAddress: "",
    businessDescription: "",
    federalBusinessId: "",
    businessVerificationDocument: null,
    heardAboutUs: "Google search",
    permission: false,
  };

  // Validation schema using Yup
  const validationSchema = Yup.object({
    role: Yup.string().default("Vendor"),
    password: Yup.string()
      .required("Password is required")
      .min(8, "Password must be at least 8 characters"),
    firstName: Yup.string().required("First Name is required"),
    lastName: Yup.string().required("Last Name is required"),
    contactPersonTitle: Yup.string().required(
      " Title is required"
    ),
    email: Yup.string()
      .email("Invalid email address")
      .required(" Email is required"),
    phone: Yup.string()
      .required("Mobile Number is required")
      .matches(/^\d{10}$/, "Mobile Number must be exactly 10 digits"),
    city: Yup.string()
      .matches(/^[a-zA-Z\s]+$/, "City name must only contain letters")
      .required("City is required"),
    state: Yup.string().required("State is required"),
    zipcode: Yup.string()
      .matches(
        /^\d{5}(-\d{4})?$/,
        "Zipcode must be a valid U.S. zip code (e.g., 12345 or 12345-6789)"
      )
      .required("Zipcode is required"),
    businessName: Yup.string().required("Business Name is required"),
    businessAddress: Yup.string().required("Business Address is required"),
    businessWebsiteAddress: Yup.string(),
    businessDescription: Yup.string().required(
      "Business Description is required"
    ),

    federalBusinessId: Yup.string()
      .matches(/^\d{9}$/, "Federal Id/Tax Id must be exactly 9 digits")
      .required("Federal ID is required"),
    businessVerificationDocument: Yup.mixed().required(
      "Business Verification Document is required"
    ),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      window.scrollTo({ top: 0, behavior: "smooth" });
      const formData = new FormData();
      Object.keys(values).forEach((key) => {
        formData.append(key, values[key]);
      });
      if (values.businessVerificationDocument) {
        formData.append(
          "businessVerificationDocument",
          values.businessVerificationDocument
        );
      }
      console.log("This is form data",formData)
      try {
        const response = await vendorRegister(values).unwrap();
        console.log(values)
        if (response?.success) {
          toast.success("Vendor registered successfully!");
          setTimeout(() => {
            navigate("/registration-complete");
          }, 2000);
        } else {
          await toast.success("Vendor registered successfully.", {
            autoClose: 5000,
            position: "top-right",
          });
          setTimeout(() => {
            navigate("/registration-complete");
          }, 2000);
        }
      } catch (err) {
        toast.error("Email or Phone No. already Exists");
        console.log("Error in submission", err);
      }
    },
  });

  // Handle file change
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    formik.setFieldValue("businessVerificationDocument", file);
  };

  useEffect(()=>{
    window.scrollTo(0,0)
  })

  return (
    <div className="max-w-4xl mx-auto py-8">
      <ToastContainer />
      <h1 className="text-3xl font-semibold text-center mb-6 text-gray-700 ">
        Registration Form
      </h1>

      <form
        className="bg-white p-6 rounded-lg shadow-lg space-y-6 border-2 border-green-300"
        onSubmit={formik.handleSubmit}
      >
        <div className="hidden">
          <label className="block text-sm font-medium text-gray-700">
            Role
          </label>
          <input
            type="text"
            name="role"
            value="Vendor"
            readOnly
            className="mt-1 w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
          />
          {formik.touched.role && formik.errors.role && (
            <div className="text-red-500 text-sm font-bold">
              {formik.errors.role}
            </div>
          )}
        </div>
        <div className="hidden">
          <label className="block text-sm font-medium text-gray-700">
            Permission
          </label>
          <input
            type="text"
            name="permission"
            value="permission"
            readOnly
            className="mt-1 w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
          />
          {formik.touched.permission && formik.errors.permission && (
            <div className="text-red-500 text-sm font-bold">
              {formik.errors.permission}
            </div>
          )}
        </div>
        <div className="grid grid-cols-3 gap-4">
          <div>
            <input
              type="text"
              name="firstName"
              placeholder="First Name"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.firstName}
              className="mt-1 w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 placeholder-gray-600"
            />
            {formik.touched.firstName && formik.errors.firstName && (
              <div className="text-red-500 text-sm font-bold">
                {formik.errors.firstName}
              </div>
            )}
          </div>

          <div>
            <input
              type="text"
              name="lastName"
              placeholder="Last Name"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.lastName}
              className="mt-1 w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 placeholder-gray-600"
            />
            {formik.touched.lastName && formik.errors.lastName && (
              <div className="text-red-500 text-sm font-bold">
                {formik.errors.lastName}
              </div>
            )}
          </div>
          <div>
            <input
              type="text"
              name="contactPersonTitle"
              placeholder="Title (CEO/Owner/Founder)"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.contactPersonTitle}
              className=" w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 placeholder-gray-600"
            />
            {formik.touched.contactPersonTitle &&
              formik.errors.contactPersonTitle && (
                <div className="text-red-500 text-sm font-bold">
                  {formik.errors.contactPersonTitle}
                </div>
              )}
          </div>
        </div>
        <div className="grid grid-cols-3 gap-4">
          {/* Email ID */}
          <div>
            <input
              type="email"
              name="email"
              placeholder="Email"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
              className="w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 placeholder-gray-600"
            />
            {formik.touched.email && formik.errors.email && (
              <div className="text-red-500 text-sm font-bold">
                {formik.errors.email}
              </div>
            )}
          </div>

          {/* Mobile Number */}
          <div>
            <input
              type="text"
              name="phone"
              placeholder="Mobile Number"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.phone}
              className="w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 placeholder-gray-600"
            />
            {formik.touched.phone && formik.errors.phone && (
              <div className="text-red-500 text-sm font-bold">
                {formik.errors.phone}
              </div>
            )}
          </div>
          <div>
            <input
              id="password"
              type="password"
              name="password"
              placeholder="Desired password"
              className="w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 placeholder-gray-600"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.password}
            />
            {formik.touched.password && formik.errors.password ? (
              <div className="text-red-500 text-sm font-bold">
                {formik.errors.password}
              </div>
            ) : null}
          </div>
        </div>

        <div className="grid grid-cols-3 gap-4">
          {/* Business Name */}
          <div>
            <input
              type="text"
              name="businessName"
              placeholder="Business Name"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.businessName}
              className="mt-1 w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 placeholder-gray-600"
            />
            {formik.touched.businessName && formik.errors.businessName && (
              <div className="text-red-500 text-sm font-bold">
                {formik.errors.businessName}
              </div>
            )}
          </div>
          <div>
            <input
              type="url"
              name="businessWebsiteAddress"
              placeholder=" Website (if any) "
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.businessWebsiteAddress}
              className="mt-1 w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 placeholder-gray-600"
            />
            {formik.touched.businessWebsiteAddress &&
              formik.errors.businessWebsiteAddress && (
                <div className="text-red-500 text-sm font-bold">
                  {formik.errors.businessWebsiteAddress}
                </div>
              )}
          </div>
          <div>
            <input
              type="text"
              name="businessDescription"
              placeholder="Business Description"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.businessDescription}
              className="mt-1 w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 placeholder-gray-600"
            />
            {formik.touched.businessDescription &&
              formik.errors.businessDescription && (
                <div className="text-red-500 text-sm font-bold">
                  {formik.errors.businessDescription}
                </div>
              )}
          </div>
        </div>
        <div>
          <input
            type="text"
            name="businessAddress"
            placeholder="Business Address"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.businessAddress}
            className="mt-1 w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 placeholder-gray-600"
          />
          {formik.touched.businessAddress && formik.errors.businessAddress && (
            <div className="text-red-500 text-sm font-bold">
              {formik.errors.businessAddress}
            </div>
          )}
        </div>

        {/* City, State & Zipcode */}
        <div className="grid grid-cols-3 gap-4">
          <div>
            <input
              type="text"
              name="city"
              placeholder="City"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.city}
              className="mt-1 w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 placeholder-gray-600"
            />
            {formik.touched.city && formik.errors.city && (
              <div className="text-red-500 text-sm font-bold font-bold">
                {formik.errors.city}
              </div>
            )}
          </div>

          <div>
            <input
              type="text"
              name="state"
              placeholder="State"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.state}
              className="mt-1 w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 placeholder-gray-600"
            />
            {formik.touched.state && formik.errors.state && (
              <div className="text-red-500 text-sm font-bold">
                {formik.errors.state}
              </div>
            )}
          </div>

          <div>
            <input
              type="text"
              name="zipcode"
              placeholder="Zipcode"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.zipcode}
              className="mt-1 w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 placeholder-gray-600"
            />
            {formik.touched.zipcode && formik.errors.zipcode && (
              <div className="text-red-500 text-sm font-bold">
                {formik.errors.zipcode}
              </div>
            )}
          </div>
        </div>
        <div>
          <input
            type="text"
            name="federalBusinessId"
            placeholder=" Federal Business ID / Tax ID* "
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.federalBusinessId}
            className="mt-1 w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 placeholder-gray-600"
          />
          {formik.touched.federalBusinessId &&
            formik.errors.federalBusinessId && (
              <div className="text-red-500 text-sm font-bold">
                {formik.errors.federalBusinessId}
              </div>
            )}
        </div>
        <div>
          <label htmlFor="businessVerificationDocument">
            Please upload one of the documents in JPG or PNG format.{" "}
          </label>
          <p className="text-sm text-gray-600 mb-2">
            - Business License <br />
            - Reseller Tax ID <br />
            - Federal Tax ID/EIN <br />- OTP (Other Tobacco Products) Tax Permit
          </p>
          <input
            id="businessVerificationDocument"
            name="businessVerificationDocument"
            type="file"
            onChange={handleFileChange}
          />
          {formik.touched.businessVerificationDocument &&
          formik.errors.businessVerificationDocument ? (
            <div className="text-red-500 text-sm font-bold">
              {formik.errors.businessVerificationDocument}
            </div>
          ) : null}
        </div>

        <div className="mx-auto flex justify-center">
          <button
            type="submit"
            className="w-64 bg-blue-500 text-white py-2 px-4 rounded-lg"
          >
            {formik.isSubmitting ? "Submitting..." : "Submit"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default VendorRegister;
