import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const apiUrl = process.env.REACT_APP_API_BASE_URL;

export const shippingApiSlice = createApi({
  reducerPath: "shippingApi",
  baseQuery: fetchBaseQuery({ baseUrl: apiUrl }),
  endpoints: (builder) => ({
    fetchAllShippingMethod: builder.query({
      query: () => `/shipping`,
    }),
    fetchShippingByCode: builder.query({
      query: (id) => `/shipping/code/${id}`,
    }),
    fetchCouponByCode: builder.query({
      query: (id) => `/coupon/${id}`,
    }),
  }),
});

export const {
  useFetchShippingByCodeQuery,
  useFetchCouponByCodeQuery,
  useFetchAllShippingMethodQuery,
} = shippingApiSlice;

export const shippingApiReducer = shippingApiSlice.reducer;
