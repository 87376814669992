import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const apiUrl = process.env.REACT_APP_API_BASE_URL;

export const productApiSlice = createApi({
  reducerPath: "productsApi",
  baseQuery: fetchBaseQuery({ baseUrl: apiUrl }),
  endpoints: (builder) => ({
    fetchAllFilteredProducts: builder.query({
      query: ({ category, minPrice, maxPrice,  searchTerm ,page,limit}) => {
        let queryParams = `page=${page}&limit=${limit}`; 
        if (category) {
          queryParams += `&category=${category}`;
        }
        if (minPrice) {
          queryParams += `&minPrice=${minPrice}`;
        }
        if (maxPrice) {
          queryParams += `&maxPrice=${maxPrice}`;
        }

        if (searchTerm) {
          queryParams += `&searchTerm=${searchTerm}`;
        }
        return `/products/get/allProducts?${queryParams}`;
      },
    }),
    fetchAllCategory: builder.query({
      query: () => "/category",
    }),
    fetchCategoriesName: builder.query({
      query: () => "/categories",
    }),
    fetchAllOffers: builder.query({
      query: () => "/coupon",
    }),
    // fetchProductById: builder.query({
    //   query: (slug) => `/products/${slug}`,
    // }),
    fetchProductById: builder.query({
      query: (id) => `/products/${id}`,
    }),
    fetchProductsByCategory: builder.query({
      query: (id) => `/products/category/${id}`,
    }),
    fetchProductsByVendor: builder.query({
      query: (id) => `/products/vendor/${id}`,
    }),
    fetchgetHome: builder.query({
      query: (id) => `/products/get/homeProducts`,
    }),
  }),
});

export const {
  useFetchAllFilteredProductsQuery,
  useFetchAllCategoryQuery,
  useFetchAllOffersQuery,
  useFetchCategoriesNameQuery,
  useFetchProductByIdQuery,
  useFetchProductsByCategoryQuery,
  useFetchProductsByVendorQuery,
  useFetchgetHomeQuery
} = productApiSlice;

export const productApiReducer = productApiSlice.reducer;
