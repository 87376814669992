export const FooterData = [
  {
    titleHeader: "Company",
    title: [
      {
        Name: "About Us",
        href: "about-us",
      },
      {
        Name: "Contact Us",
        href: "contact-us",
      },
    ],
  },
  {
    titleHeader: "Top Category",
    title: [
      {
        Name: "Nicotine Shots",
        href: "Nicotine-Shots",
      },
      {
        Name: "Kratom",
        href: "Kratom",
      },
      {
        Name: "Hardware",
        href: "Hardware",
      },
      {
        Name: "E-Liquids",
        href: "E-Liquids",
      },
    ],
  },
  // {
  //   titleHeader: "My Account",
  //   title: [
  //     {
  //       Name: "Dashboard",
  //       href: "dashboard",
  //     },
  //     {
  //       Name: "My Orders",
  //       href: "my-orders",
  //     },
  //     {
  //       Name: "Recent Orders",
  //       href: "dashboard",
  //     },
  //     {
  //       Name: "Updated Profile",
  //       href: "updated-profile",
  //     },
  //   ],
  // },

];
