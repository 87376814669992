import React, { useState, useEffect } from "react";
import Card from "../card/Card";
import { useFetchAllCategoryQuery, useFetchAllFilteredProductsQuery } from "../../store/api/ProductSlice";

function AllProducts() {
  const [category, setCategory] = useState("");
  const [minPrice, setMinPrice] = useState("");
  const [maxPrice, setMaxPrice] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(1);

  // Fetch products with filters and pagination
  const { data, isLoading, error } = useFetchAllFilteredProductsQuery({
    category,
    minPrice,
    maxPrice,
    searchTerm,
    page,
    limit: 30,
  });

 console.log("This is data",data)

  const { data: categoryData } = useFetchAllCategoryQuery();

  // Update category, price, or search term
  const handleCategoryChange = (e) => setCategory(e.target.value);
  const handleMinPriceChange = (e) => setMinPrice(e.target.value);
  const handleMaxPriceChange = (e) => setMaxPrice(e.target.value);
  const handleSearchTermChange = (e) => setSearchTerm(e.target.value);

  // Update page on next/previous button click
  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= data?.pagination?.totalPages) {
      setPage(newPage);
    }
  };

  useEffect(() => {
    // This will refetch products whenever page or filters change
    if (page > 1 && page <= data?.pagination?.totalPages) {
      setPage(page);
    }
  }, [category, minPrice, maxPrice, searchTerm, page, data]);

  return (
    <div
      id=""
      className="bg-gray-50 lg:py-16 py-10 mx-auto max-w-screen-2xl px-3 sm:px-10"
    >
      {/* Filters Section */}
      <div className="mb-10 bg-white p-6 rounded-lg shadow-lg -mt-10 border border-gray-100">
        <h3 className="text-2xl font-semibold mb-6 text-center text-gray-900">
          Filter Products
        </h3>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
          {/* Category Filter */}
          <div className="space-y-2">
            <label
              htmlFor="category"
              className="text-sm font-medium text-gray-700"
            >
              Category
            </label>
            <select
              id="category"
              className="w-full p-3 border border-gray-300 rounded-md shadow-sm focus:ring-2 focus:ring-blue-500 transition duration-300 ease-in-out transform hover:scale-105 appearance-none"
              onChange={handleCategoryChange}
              value={category}
            >
              <option value="">Select Category</option>
              {categoryData &&
                categoryData.map((category) => (
                  <option key={category._id} value={category.name}>
                    {category.parent}
                  </option>
                ))}
            </select>
            <div className="absolute inset-y-0 right-3 flex items-center pointer-events-none">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-caret-down-fill text-gray-700"
                viewBox="0 0 16 16"
              >
                <path d="M1.5 4a.5.5 0 0 1 .8-.4L8 8.567 13.7 3.6a.5.5 0 1 1 .6.8l-5 5.5a.5.5 0 0 1-.6 0l-5-5.5a.5.5 0 0 1-.4-.8z" />
              </svg>
            </div>
          </div>

          <div className="space-y-2">
            <label
              htmlFor="minPrice"
              className="text-sm font-medium text-gray-700"
            >
              Min Price
            </label>
            <div className="relative">
              <div className="absolute inset-y-0 left-3 flex items-center">
                <span className="text-gray-700">$</span>
              </div>
              <input
                type="number"
                id="minPrice"
                className="w-full p-3 pl-7 border border-gray-300 rounded-md shadow-sm focus:ring-2 focus:ring-blue-500 pr-10"
                placeholder="Min Price"
                value={minPrice}
                onChange={(e) => handleMinPriceChange(e)}
              />
              <div className="absolute inset-y-0 right-2 flex flex-col justify-center items-center ">
                <button
                  type="button"
                  className="text-gray-700 hover:bg-gray-200 rounded-full "
                  onClick={() => setMinPrice((prev) => Number(prev) + 1)}
                >
                  ▲
                </button>
                <button
                  type="button"
                  className="text-gray-700 hover:bg-gray-200 rounded-full "
                  onClick={() =>
                    setMinPrice((prev) => Math.max(0, Number(prev) - 1))
                  }
                >
                  ▼
                </button>
              </div>
            </div>
          </div>

          {/* Max Price Filter */}
          <div className="space-y-2">
            <label
              htmlFor="maxPrice"
              className="text-sm font-medium text-gray-700"
            >
              Max Price
            </label>
            <div className="relative">
              <div className="absolute inset-y-0 left-3 flex items-center">
                <span className="text-gray-700">$</span>
              </div>
              <input
                type="number"
                id="maxPrice"
                className="w-full p-3 pl-7 border border-gray-300 rounded-md shadow-sm focus:ring-2 focus:ring-blue-500 pr-10"
                placeholder="Max Price"
                value={maxPrice}
                onChange={(e) => handleMaxPriceChange(e)}
              />

              <div className="absolute inset-y-0 right-2 flex flex-col justify-center items-center">
                <button
                  type="button"
                  className="text-gray-700 hover:bg-gray-200 rounded-full"
                  onClick={() => setMaxPrice((prev) => Number(prev) + 1)}
                >
                  ▲
                </button>
                <button
                  type="button"
                  className="text-gray-700 hover:bg-gray-200 rounded-full"
                  onClick={() =>
                    setMaxPrice((prev) => Math.max(0, Number(prev) - 1))
                  }
                >
                  ▼
                </button>
              </div>
            </div>
          </div>

          {/* Search Term Filter */}
          <div className="space-y-2">
            <label
              htmlFor="searchTerm"
              className="text-sm font-medium text-gray-700"
            >
              Search By Brand or Flavour
            </label>
            <input
              type="text"
              id="searchTerm"
              placeholder="Search products"
              className="w-full p-3 border border-gray-300 rounded-md shadow-sm focus:ring-2 focus:ring-blue-500 transition duration-300 ease-in-out transform hover:scale-105"
              value={searchTerm}
              onChange={handleSearchTermChange}
            />
          </div>
        </div>
      </div>

      {/* Product List */}
      <div className="flex">
        <div className="w-full">
          <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-5 xl:grid-cols-5 2xl:grid-cols-6 gap-2 md:gap-3 lg:gap-3">
            {isLoading ? (
              <div className="flex items-center justify-center space-x-2">
                <div className="w-6 h-6 border-4 border-t-4 border-blue-500 border-solid rounded-full animate-spin"></div>
                <span className="text-lg font-semibold text-gray-700">
                  Loading...
                </span>
              </div>
            ) : (
              data?.products?.map((product) => (
                <Card key={product._id} data={product} />
              ))
            )}
          </div>
        </div>
      </div>

      {/* Pagination Controls */}
      <div className="pagination-controls flex items-center justify-center space-x-4 mt-6">
        {/* Previous Button */}
        <button
          disabled={page === 1}
          onClick={() => handlePageChange(page - 1)}
          className="btn-prev px-4 py-2 text-sm font-semibold text-white bg-blue-600 rounded-md shadow-md disabled:bg-gray-300 disabled:cursor-not-allowed hover:bg-blue-700 transition-all duration-200 ease-in-out"
        >
          Previous
        </button>

        {/* Page Info */}
        <span className="page-info text-sm font-medium text-gray-700">
          Page {page} of {data?.pagination?.totalPages || 1}
        </span>

        {/* Next Button */}
        <button
          disabled={page === data?.pagination?.totalPages}
          onClick={() => handlePageChange(page + 1)}
          className="btn-next px-4 py-2 text-sm font-semibold text-white bg-blue-600 rounded-md shadow-md disabled:bg-gray-300 disabled:cursor-not-allowed hover:bg-blue-700 transition-all duration-200 ease-in-out"
        >
          Next
        </button>
      </div>
    </div>
  );
}

export default AllProducts;
