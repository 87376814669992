import React from "react";

function Informations() {
  return (
    <div>
      
    </div>
    // <div className="w-full xl:w-5/12 lg:w-6/12 md:w-5/12">
    //   <div className="mt-6 md:mt-0 lg:mt-0 bg-gray-50 border border-gray-100 p-4 lg:p-8 rounded-lg">
    //     <ul className="my-0">
    //       <li className="flex items-center py-3">
    //         <span className="text-xl text-gray-400 items-start mr-4">
    //           <svg
    //             stroke="currentColor"
    //             fill="none"
    //             strokeWidth="2"
    //             viewBox="0 0 24 24"
    //             strokeLinecap="round"
    //             strokeLinejoin="round"
    //             height="1em"
    //             width="1em"
    //             xmlns="http://www.w3.org/2000/svg"
    //           >
    //             <rect x="1" y="3" width="15" height="13"></rect>
    //             <polygon points="16 8 20 8 23 11 23 16 16 16 16 8"></polygon>
    //             <circle cx="5.5" cy="18.5" r="2.5"></circle>
    //             <circle cx="18.5" cy="18.5" r="2.5"></circle>
    //           </svg>
    //         </span>
    //         <p className="font-sans leading-5 text-sm text-gray-500">
    //           Free shipping apply to all orders over shipping{" "}
    //           <span className="font-semibold">$100</span>
    //         </p>
    //       </li>
    //       <li className="flex items-center py-3">
    //         <span className="text-xl text-gray-400 items-start mr-4">
                
    //           <svg
    //             stroke="currentColor"
    //             fill="none"
    //             strokeWidth="2"
    //             viewBox="0 0 24 24"
    //             strokeLinecap="round"
    //             strokeLinejoin="round"
    //             height="1em"
    //             width="1em"
    //             xmlns="http://www.w3.org/2000/svg"
    //           >
    //             <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
    //             <polyline points="9 22 9 12 15 12 15 22"></polyline>
    //           </svg>
    //         </span>
    //         <p className="font-sans leading-5 text-sm text-gray-500">
    //           Home Delivery within <span className="font-semibold">1 Hour</span>
    //         </p>
    //       </li>
    //       <li className="flex items-center py-3">
    //         <span className="text-xl text-gray-400 items-start mr-4">
    //           <svg
    //             stroke="currentColor"
    //             fill="none"
    //             strokeWidth="2"
    //             viewBox="0 0 24 24"
    //             strokeLinecap="round"
    //             strokeLinejoin="round"
    //             height="1em"
    //             width="1em"
    //             xmlns="http://www.w3.org/2000/svg"
    //           >
    //             <line x1="12" y1="1" x2="12" y2="23"></line>
    //             <path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path>
    //           </svg>
    //         </span>
    //         <p className="font-sans leading-5 text-sm text-gray-500">
    //           Cash on Delivery Available
    //         </p>
    //       </li>
    //       <li className="flex items-center py-3">
    //         <span className="text-xl text-gray-400 items-start mr-4">
    //           <svg
    //             stroke="currentColor"
    //             fill="none"
    //             strokeWidth="2"
    //             viewBox="0 0 24 24"
    //             strokeLinecap="round"
    //             strokeLinejoin="round"
    //             height="1em"
    //             width="1em"
    //             xmlns="http://www.w3.org/2000/svg"
    //           >
    //             <polyline points="17 1 21 5 17 9"></polyline>
    //             <path d="M3 11V9a4 4 0 0 1 4-4h14"></path>
    //             <polyline points="7 23 3 19 7 15"></polyline>
    //             <path d="M21 13v2a4 4 0 0 1-4 4H3"></path>
    //           </svg>
    //         </span>
    //         <p className="font-sans leading-5 text-sm text-gray-500">
    //           <span className="font-semibold">7</span> Days returns money back
    //           guarantee
    //         </p>
    //       </li>
    //       <li className="flex items-center py-3">
    //         <span className="text-xl text-gray-400 items-start mr-4">
    //           <svg
    //             stroke="currentColor"
    //             fill="none"
    //             strokeWidth="2"
    //             viewBox="0 0 24 24"
    //             strokeLinecap="round"
    //             strokeLinejoin="round"
    //             height="1em"
    //             width="1em"
    //             xmlns="http://www.w3.org/2000/svg"
    //           >
    //             <path d="M19.69 14a6.9 6.9 0 0 0 .31-2V5l-8-3-3.16 1.18"></path>
    //             <path d="M4.73 4.73L4 5v7c0 6 8 10 8 10a20.29 20.29 0 0 0 5.62-4.38"></path>
    //             <line x1="1" y1="1" x2="23" y2="23"></line>
    //           </svg>
    //         </span>
    //         <p className="font-sans leading-5 text-sm text-gray-500">
    //           Warranty not available this item
    //         </p>
    //       </li>
    //       <li className="flex items-center py-3">
    //         <span className="text-xl text-gray-400 items-start mr-4">
    //           <svg
    //             stroke="currentColor"
    //             fill="none"
    //             strokeWidth="2"
    //             viewBox="0 0 24 24"
    //             strokeLinecap="round"
    //             strokeLinejoin="round"
    //             height="1em"
    //             width="1em"
    //             xmlns="http://www.w3.org/2000/svg"
    //           >
    //             <circle cx="12" cy="12" r="5"></circle>
    //             <line x1="12" y1="1" x2="12" y2="3"></line>
    //             <line x1="12" y1="21" x2="12" y2="23"></line>
    //             <line x1="4.22" y1="4.22" x2="5.64" y2="5.64"></line>
    //             <line x1="18.36" y1="18.36" x2="19.78" y2="19.78"></line>
    //             <line x1="1" y1="12" x2="3" y2="12"></line>
    //             <line x1="21" y1="12" x2="23" y2="12"></line>
    //             <line x1="4.22" y1="19.78" x2="5.64" y2="18.36"></line>
    //             <line x1="18.36" y1="5.64" x2="19.78" y2="4.22"></line>
    //           </svg>
    //         </span>
    //         <p className="font-sans leading-5 text-sm text-gray-500">
    //           Guaranteed 100% organic from natural products.
    //         </p>
    //       </li>
    //       <li className="flex items-center py-3">
    //         <span className="text-xl text-gray-400 items-start mr-4">
    //           <svg
    //             stroke="currentColor"
    //             fill="none"
    //             strokeWidth="2"
    //             viewBox="0 0 24 24"
    //             strokeLinecap="round"
    //             strokeLinejoin="round"
    //             height="1em"
    //             width="1em"
    //             xmlns="http://www.w3.org/2000/svg"
    //           >
    //             <path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path>
    //             <circle cx="12" cy="10" r="3"></circle>
    //           </svg>
    //         </span>
    //         <p className="font-sans leading-5 text-sm text-gray-500">
    //           Delivery from our pick point Cecilia Chapman, 561-4535 Nulla LA,
    //           United States 96522
    //         </p>
    //       </li>
    //     </ul>
    //   </div>
    // </div>
  );
}

export default Informations;
