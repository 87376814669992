import React, { useEffect } from "react";
import aboutUs from "../assets/img/about-us.png";
import aboutVegetables from "../assets/img/about-vegetables.png";
import men1 from "../assets/img/men-1.png";
import men2 from "../assets/img/men-2.png";
import men3 from "../assets/img/men-3.png";
import women1 from "../assets/img/women-1.png";
import women2 from "../assets/img/women-2.png";
import women3 from "../assets/img/women-3.png";

const About = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="">
<h2 className="flex justify-center text-center mt-10 mb-10">Coming Soon</h2>
</div>
  );
}

export default About;
