import React from "react";
import AppDownloadLeft from "../../assets/img/app-download-img-left.png";
import AppDownloadRight from "../../assets/img/app-download-img.png";
import { Link } from "react-router-dom";
function MobileApp() {
  return (
    <div className="bg-indigo-50 py-10 lg:py-16 bg-repeat bg-center overflow-hidden">
      <div className="max-w-screen-2xl mx-auto px-4 sm:px-10">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-3 gap-2 md:gap-3 lg:gap-3 items-center">
          <div className="flex-grow hidden lg:flex md:flex md:justify-items-center lg:justify-start ">
            <span
              style={{
                boxSizing: "border-box",
                display: "inline-block",
                overflow: "hidden",
                width: "initial",
                height: "initial",
                background: "none",
                opacity: "1",
                border: "0",
                margin: "0",
                padding: "0",
                position: "relative",
                maxWidth: "100%",
              }}
            >
              <img className="block w-auto" src={AppDownloadLeft} />
            </span>
          </div>
          <div className="text-center">
          <h3 className="text-3xl md:text-4xl lg:text-4xl font-extrabold text-gray-900 mb-5">
  Sell on Pufframa
</h3>
<Link to={`/vendor-registration`} className="text-lg md:text-xl font-semibold text-white hover:text-white no-underline  rounded-lg py-2 px-6 transition-all duration-300 ease-in-out shadow-md hover:shadow-lg" style={{backgroundColor:'#10B981',textDecoration:'none'}}>
  Register Now
</Link>

          
          </div>
          <div className="md:hidden lg:block ">
            <div className="flex-grow hidden lg:flex md:flex lg:justify-end">
              <span
                style={{
                  boxSizing: "border-box",
                  display: "inline-block",
                  overflow: "hidden",
                  width: "initial",
                  height: "initial",
                  background: "none",
                  opacity: "1",
                  border: "0",
                  margin: "0",
                  padding: "0",
                  position: "relative",
                  maxWidth: "100%",
                }}
              >
                <img className="block w-auto" src={AppDownloadRight} />
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MobileApp;
